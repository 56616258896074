var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 2000;

var subsiteHijackMainNav = ['our-shops'];
var subsiteHijackMainLogoClickDestination = ['our-shops'];

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

// Social Icons in the Admin bar
$(".headerContent ul.socialIcons li").clone().appendTo(".menuAdminContainer #menuAdmin").css("order", "5");

// Search bar in the Admin bar
$(".headerContent form.header-search").clone().appendTo(".menuAdminContainer #menuAdmin").css("order", "4");

// Donate Button in the Admin bar
$(".headerContent .mainCallToAction a.cta-button.donate").clone().appendTo(".menuAdminContainer #menuAdmin").css("order", "6");

// Footer Logos after PageFooter
$(".footerBox.Footerlogos").insertAfter(".pageFooter");

// Memory tree appeal customisation
if ($('body').hasClass('memory-tree')){
    $('label[for="donationComment"]').text("Please let us know who you are donating in memory of and what name you'd like engraved on your leaf.");
}

// Form button text change
$("body.forms-retail-volunteer-application-form a.button.customFormSubmit").text("Send application form");

// DES-4683
if ($('body').hasClass('donate') && $('input#displayName').prop('checked') == true ) {
  $('input#displayName').click();
}

// DES-4713
if ($('body').hasClass('subsite')){
    $('.subsiteNav').insertBefore('ul.topLevel');
}

// DES-5042
$(".formQuestion.donationGiftAid .giftAidStatement label").text("Yes - I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years. I understand I must pay enough income tax and/or capital gains tax each tax year to cover the amount of Gift Aid that all charities and community amateur sports clubs claim on my donations in that tax year, and I am responsible for paying any difference.");

// IM-721
$(".homefeaturecategory-homeboxquickgiving").insertBefore(".homeFeaturesWrapper");


// Remove if empty
$('.feedsTitle').each(function() {

  if( $(this).text() == '') {
    $(this).remove();
  }

});